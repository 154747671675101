import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/404.scss"
import BodyClassName from 'react-body-classname';
import { Link } from "gatsby";

const NotFoundPage = () => (
  <BodyClassName className="body-404">
    <div class="container">

      <div class="form-signin text-white pb-5">
        <div class="m-5 text-center">
          <h1 class="pt-5" style={{fontFamily: 'MinecraftFive'}}>404</h1>
          <hr />
          <h2 style={{fontFamily: 'MinecraftSeven' }}>You died!</h2>
          <h5 class="my-2" style={{fontFamily: 'MinecraftSeven' }}>You tried to swim in Lava at an unknown Page</h5>
          <div class="d-inline">
            <h4 style={{fontFamily: 'MinecraftSeven' }} >Score: <span class="text-warning">0</span></h4>
          </div>
        </div>

      </div>

      <div class="m-5">
        <Link to='/'>
          <button type="button" style={{fontFamily: 'MinecraftFive' }} class="btn btn-secondary text-black btn-lg btn-block">Respawn</button>
        </Link>
      </div>

    </div>
  </BodyClassName>
)

export default NotFoundPage;

